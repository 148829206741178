import { StaticImage } from "gatsby-plugin-image"
import * as React from "react"
import Layout from "../components/core/Layout"
import Navigation from "../components/core/Navigation"

import locationIcon from "../images/common/location-icon.svg"
import phoneIcon from "../images/common/phone-icon.svg"
import beyondSymbol from "../images/common/symbol-beyond.svg"
import quoteIcon from "../images/common/quote-icon.svg"


const AboutPage = () => {
    return (
        <Layout siteTitle="About - Dr. Antoaneta Mueller, M.D., FACOG">
            <section id="about-hero">
                <div className="container"><Navigation /></div>
                <div className="container">
                    <StaticImage alt="Dr. Antoaneta Mueller, M.D., FACOG, Premier Board Certified Ob-Gyn - South Bay CA" className="profile-img" quality="100" src="../images/about/about-image@2x.png" />
                    <p className="about-title-prim">About Me</p>
                    <p className="profile-name">Dr. Antoaneta Mueller, M.D.</p>
                    <span className="cert-title">FACOG</span>
                </div>
                <div className="container">

                </div>
                <div className="hero-bg"></div>
            </section>


            <section id="about-details">
                <div className="container">
                    <div className="about-grid">
                            <div className="about-desc-wrapper">
                                    <div className="about-desc">
                                    <img alt="Quote Icon" className="about-icn" src={quoteIcon} />
                                        <p>I am passionate about women’s health care and that is why I love my job.</p>
                                        <p>Women’s health is a continuum from adolescence into menopause and at each stage your needs and expectations change. As an Ob- Gyn I have dedicated my career to promoting an integrative approach no matter what stage of life you are in. My approach is compassionate and patient oriented.</p>
                                        <p>As a working mother of two teenagers I relate to my patients tremendously. I cherish the relationships I have built thus far and look forward to forging new ones in the years to come.</p>
                                    </div>

                                    <div className="about-desc-paragraph">
                                        <p>Having served in the Long Beach community since 2007 one of my greatest honors was acting as <b>Medical Director of the Gyn Emergent Care Center.</b> This facility was open 24/7 and provided immediate care to women suffering from obstetrical and Gynecological emergencies. I was in charge of training and implementing protocols. Women today have a lot on their plate between work and family responsibilities. We recognized the importance of addressing their health needs immediately so that they could return to their daily life.</p>
                                        <p>As <b>Medical Director of Complete Women Care,</b> my responsibilities often entailed addressing patient concerns regarding care. This allowed me to create a culture of constant feedback to the younger physicians in order to cultivate a compassion and patient centered approach to health care.</p>
                                    </div>

                                    <div className="about-desc">
                                        <p>Opening up my own practice has always been a dream of mine and I look forward to incorporating all of my past experiences to this new chapter in my life.</p>
                                    </div>
                            </div>


                            <div className="profile-experience exp-first">
                                    <div className="profile-exp-title"><span className="exp-label">EXPERIENCE</span><img alt="Beyond Symbol" className="beyond-symbol" src={beyondSymbol} /></div>
                                    <div className="profile-info-grp">
                                        <p className="primary">Medical Director at Complete Women Care</p>
                                        <p className="secondary">Long Beach, CA</p>
                                        <p className="tertiary">2007-2021</p>
                                    </div>
                                    <div className="profile-info-grp">
                                        <p className="primary">Medical Director of Gyn Emergent Care Center</p>
                                        <p className="secondary">Long Beach, CA</p>
                                        <p className="secondary">Providing 24/7 Gyn Emergent Care to all women</p>
                                        <p className="tertiary">2013-2021</p>
                                    </div>
                            </div>
                            <div className="profile-experience exp-second">
                                    <div className="profile-exp-title"><span className="exp-label">EDUCATION AND TRAINING</span><img alt="Beyond Symbol" className="beyond-symbol symbol-second" src={beyondSymbol} /></div>
                                    <div className="profile-info-grp">
                                        <p className="primary">Residency</p>
                                        <p className="secondary">Jackson Memorial Hospital</p>
                                        <p className="secondary">University of Miami Miller School of Medicine</p>
                                        <p className="tertiary">2003-2007</p>
                                    </div>
                                    <div className="profile-info-grp">
                                        <p className="primary">Medical School</p>
                                        <p className="secondary">University of Miami Miller School of Medicine</p>
                                        <p className="tertiary">1999-2003</p>
                                    </div>
                                    <div className="profile-info-grp">
                                        <p className="primary">Research</p>
                                        <p className="secondary">Wake Forest University</p>
                                        <p className="secondary">Bowman Gray Medical School</p>
                                        <p className="tertiary">1998-1999</p>
                                    </div>
                                    <div className="profile-info-grp">
                                        <p className="primary">Bachelor of Science in Biology</p>
                                        <p className="secondary">Salem College</p>
                                        <p className="secondary">Winston-Salem, NC</p>
                                        <p className="tertiary">1994-1998</p>
                                    </div>
                            </div>

                     </div>
                </div>
            </section>
          

            <section id="ready-to-schedule">
                <div className="container">
                    <p className="schedule-title">Ready To Schedule?</p>
                    <p className="schedule-desc">Let’s reach your health goals together. <br /> We’re here for you.</p>
                    <a className="schedule-now-btn" href="#contact">
                        <div className="btn-container">
                            <div className="btn-content">BOOK APPOINTMENT</div>
                        </div>
                    </a>
                </div>
            </section>

            <section id="contact">
                <div className="container">
                    <div className="columns">
                        <div className="column is-6 contact-info-grp">
                            <div className="tile is-ancestor is-vertical">
                                <div className="tile contact-title">Our Offices</div>
                                <div className="tile contact-title-name">BEYOND WOMEN’S HEALTH</div>
                                <div className="tile contact-desc"><img alt="Location" className="contact-image" quality="100" src={locationIcon} />3655 Lomita Blvd Suite 321<br /> Torrance CA, 90505</div>
                                <a href="tel:310-325-1198" className="tile contact-number"><img alt="Call" className="contact-image" quality="100" src={phoneIcon} />(310) 325-1198</a>
                            </div>

                        </div>
                        <div className="column is-6 contact-map">
                            <StaticImage alt="Location Map for Beyond Women's Health" className="contact-map-image" quality="100" src="../images/common/location-map.png" />
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default AboutPage
